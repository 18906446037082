form {
  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }

  .form-group {
    margin: 0 0 15px 0;
  }

  input, textarea {
    display: block;
    width: 100%;
    border: 1px solid $lightGreyColor;
    border-radius: 2px;
    padding: 10px 7px;
    margin: 7px 0 0 0;
  }

  input[type="submit"],
  input[type="reset"],
  button {
    border: 2px solid $logoBlue;
    font-family: 'Sue Ellen Francisco', cursive;
    font-size: 25px;
    text-decoration: none;
    color: $logoBlue;
    padding: 6px 22px;
    transition: color 0.5s, background-color 0.5s;
    margin: 10px 0;
    display: block;
    cursor: pointer;
    background: #ffffff;

    &:hover {
      color: #ffffff;
      background-color: $logoBlue;
    }

    &:active {
      color: $linkHover;
    }
  }

  .captcha {
    img {
      width: auto;
    }
  }
}