@media only screen and (max-width: $mediaQuery3XLarge) {
  .ccm-page main .home-circle .white-circle {
    bottom: 60px;
  }
}

@media only screen and (max-width: $mediaQueryXXLarge) {
  .ccm-page {
    .container-fluid {
      max-width: 80%;
    }

    main .home-circle .white-circle {
      bottom: 30px;
    }
  }
}

@media only screen and (max-width: $mediaQueryXLarge) {
  .ccm-page main .home-circle {
    padding-right: 0;

    .button {
      right: 130px;
    }

    .white-circle {
      width: 400px;
      height: 400px;

      h1 {
        font-size: 38px;
      }
    }
  }
}

@media only screen and (max-width: $mediaQueryLarge) {
  .ccm-page {
    main .home-circle {
      margin-top: 145px;
      padding-left: 60px;
      padding-bottom: 60px;

      .button {
        right: 60px;
        bottom: 150px;
      }

      .white-circle {
        margin-left: -60px;
        bottom: 20px;
      }

      &.no-circle {
        padding: 0 70px;

        .top-image {
          width: 95%;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaQueryMedium) {
  .ccm-page {
    .container-fluid {
      max-width: 90%;
    }

    main .home-circle {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 150px;

      .top-image {
        padding: 7px;
      }

      .button {
        right: 40px;
        bottom: 80px;
      }

      .white-circle {
        width: 350px;
        height: 350px;

        h1 {
          font-size: 32px;
        }
      }

      &.maps {
        .mapsWrapper {
          height: 40vh;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaQuerySmall) {
  .ccm-page {
    .button {
      font-size: 20px;
    }

    main {
      .container-fluid {
        padding-left: 20px;
        padding-right: 20px;

        .row {
          .col-sm-5,
          .col-sm-6,
          .col-sm-7 {
            &:last-of-type {
              margin-bottom: 5px;
            }
          }

          .col-sm-5 picture img,
          .col-sm-6 picture img,
          .col-sm-7 picture img {
            padding: 5px 0;
          }
        }
      }

      .home-circle {
        padding-bottom: 200px;

        &.no-circle {
          padding: 0 40px;

          .top-image {
            width: 95%;
          }
        }

        .button {
          right: 20px;
          bottom: 20px;
        }

        .white-circle {
          width: 260px;
          height: 260px;
          margin-left: 0px;
          bottom: 40px;

          h1 {
            font-size: 28px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaQueryXSmall) {
  .ccm-page {
    main {
      .container-fluid {
        padding-left: 5px;
        padding-right: 5px;
      }

      .home-circle {
        margin-top: 120px;

        &.no-circle {
          padding: 0;
        }
      }
    }
  }
}