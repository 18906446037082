.ccm-page {
  h1, h2, h3, h4, h5, h6, p {
    &.no-margin-top {
      margin-top: 0;
    }
  }

  h1 {
    font-family: 'Sue Ellen Francisco', cursive;
    font-size: 45px;
    color: $logoBlue;
    margin-bottom: 20px;
  }

  a {
    color: $logoBlue;
    text-decoration: none;
    transition: color 0.5s;

    &:hover {
      color: $linkHover;
    }

    &:active {
      color: $linkHover;
    }
  }

  .button {
    border: 2px solid $logoBlue;
    font-family: 'Sue Ellen Francisco', cursive;
    font-size: 25px;
    text-decoration: none;
    color: $logoBlue;
    padding: 6px 22px;
    transition: color 0.5s, background-color 0.5s;
    margin: 10px 0;
    display: inline-block;

    &:hover {
      color: #ffffff;
      background-color: $logoBlue;
    }

    &:active {
      color: $linkHover;
    }
  }

  .text-muted {
    color: $lightGreyColor;
  }
}

@media only screen and (max-width: $mediaQuerySmall) {
  .ccm-page {
    main .row h2:first-of-type {
      margin-top: 20px;
    }
  }
}