@import "../node_modules/bootstrap/dist/css/bootstrap-grid";
@import "../lib/glyphicons/glyphicons";
@import "../lib/glyphicons/glyphicons-halflings";
@import "settings";

html, body {
  padding: 0;
  margin: 0;
}

.ccm-page {
  font-family: 'Open Sans', sans-serif;

  .container-fluid {
    max-width: 60%;

    img {
      max-width: 100%;
    }
  }

  .container-wide {
    max-width: 80%;
  }

  main {
    margin-bottom: 30px;
    min-height: 85vh;

    img {
      margin-bottom: 10px;
    }

    figure {
      margin: 0;
    }

    figcaption {
      color: $lightGreyColor;
    }

    .top-background {
      background: url("../img/praxis-2.jpg") center center;
      background-size: cover;
      margin-bottom: 50px;
      position: relative;

      &.home {
        min-height: 100vh;
      }

      .spacer {
        display: none;
      }
    }

    .home-circle {
      margin: 150px auto 0 auto;
      padding: 0 70px 0 300px;
      position: relative;
      width: 100%;

      .white-circle {
        position: absolute;
        background-color: #ffffff;
        width: 450px;
        height: 450px;
        border-radius: 50%;
        margin-left: -300px;
        bottom: 90px;
        display: flex;
        flex-direction: row;

        h1 {
          text-align: center;
          padding: 30px;
          flex-grow: 1;
        }
      }

      .top-image {
        width: 100%;
        background-color: #ffffff;
        padding: 15px;
      }

      .button {
        position: absolute;
        right: 200px;
        bottom: 100px;
        background-color: #ffffff;
        transition: color 0.5s, background-color 0.5s;

        &:hover {
          color: #ffffff;
          background-color: $logoBlue;
        }
      }

      &.no-circle {
        padding: 0 100px;
        transition: padding 0.5s;

        .top-image {
          width: 80%;
          margin: 0 auto;
          display: block;
        }
      }

      &.slider {
        padding: 0 100px;
        transition: padding 0.5s;

        .ccm-image-slider {
          .ccm-image-slider-inner {
            position: relative;

            .rslides_nav {
              background: white;
              opacity: inherit;
              height: $sliderNavSize;
              width: $sliderNavSize;
              margin-top: -$sliderNavSize/2;
              border-radius: $sliderNavSize/2;

              &.prev:before,
              &.next:before {
                font-family: 'Glyphicons Regular';
                line-height: $sliderNavSize;
                display: block;
                width: $sliderNavSize;
                height: $sliderNavSize;
                font-size: $sliderNavSize * 0.6;
                text-align: center;
              }

              &.prev {
                margin-left: -($sliderNavSize*0.6);
                left: 0;

                &:before {
                  content: "\E225";
                }
              }

              &.next {
                margin-right: -($sliderNavSize*0.6);
                right: 0;

                &:before {
                  content: "\E224";
                }
              }
            }
          }
        }
      }

      &.maps {
        .mapsWrapper {
          height: 60vh;
        }
      }
    }

    .content {
      .row {
        margin-bottom: 40px;

        h2:first-of-type {
          margin-top: 0;
        }

        img {
          display: block;
        }
      }
    }

    /* Icon before normal link on links page*/
    a.link {
      &:before {
        font-family: 'Glyphicons Halflings';
        content: "\e144";
        padding-right: 5px;
      }
    }

    .ccm-block-file {
      padding-bottom: 15px;

      &:last-of-type {
        padding-bottom: 0;
      }

      a:before {
        font-family: 'Glyphicons Halflings';
        content: "\e022";
        padding-right: 5px;
      }
    }

    /* Different link color in text */
    /*
    & > .container-fluid {
      p {
        a {
          color: $linkHoverInText;

          &:hover {
            color: $linkHover;
          }
        }
      }
    }
    */
  }
}

@import "inc/subpage_style";
@import "inc/typo";
@import "inc/responsive";
@import "inc/nav";
@import "inc/forms";
@import "inc/footer";