/* Project wide scss "settings" such as color variables, fonts, etc. */

$mediaQuery3XLarge: 1650px;
$mediaQueryXXLarge: 1450px;
$mediaQueryXLarge: 1330px;
$mediaQueryLarge: 1090px;
$mediaQueryMedium: 780px;
$mediaQuerySmall: 576px;
$mediaQueryXSmall: 400px;

$logoBlue: #282f56;
$linkHover: #5C67A2;
$linkHoverInText: #bfb70c;
/*$linkHover: #767515;*/
$runningTextColor: #3d3d3d;
$lightGreyColor: #9e9e9e;
$backgroundColorNav: #e5e06c;

$sliderNavSize: 50px;
