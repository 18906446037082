.ccm-page {
  header {
    position: absolute;
    z-index: 1000;
    width: 100%;
    transition: background-color 0.5s;

    .header-spacer {
      height: 40px;
      width: 100%;
      transition: height 0.5s;
    }

    &.fixed-header,
    &.mobile-nav-active {
      background-color: $backgroundColorNav;
      box-shadow: 0 1px 4px $logoBlue;
    }

    &.fixed-header {
      position: fixed;

      .header-spacer {
        height: 15px;
      }

      .logo {
        height: 50px;
      }

      .nav-button .glyphicons,
      .desktop-nav ul li {
        line-height: 50px;

        &.nav-selected {
          a:after {
            margin: 2px auto 0 auto;
            width: 32px;
            transition: width 0.5s;
          }
        }
      }
    }

    .container-fluid {
      max-width: 79%;

      img {
        max-width: none;
      }
    }

    .logo {
      height: 80px;
      display: inline-block;
      transition: height 0.5s;
    }

    nav {
      ul {
        padding: 0;
        list-style-type: none;
        margin: 0;

        li {
          a {
            color: $logoBlue;
            text-decoration: none;
            display: inline-block;

            &:hover {
              color: $linkHover;
            }
          }

          &.nav-selected {
            a:after {
              display: block;
              content: "";
              background: $logoBlue;
              width: 20px;
              height: 3px;
              margin: -25px auto 0 auto;
              transition: margin 0.5s, width 0.5s;
            }
          }
        }
      }
    }

    .desktop-nav {
      text-align: right;

      ul li {
        display: inline-block;
        line-height: 80px;
        transition: line-height 0.5s;

        a {
          padding: 0 40px;
        }

        &:first-of-type {
          a {
            padding-left: 10px;
          }
        }

        &:last-of-type {
          a {
            padding-right: 10px;
          }
        }
      }
    }

    .mobile-nav {
      display: none;
      width: 100%;
      text-align: center;
      padding: 20px 0;

      ul li {
        padding: 10px;

        a {
          padding: 10px;
        }
      }
    }

    .nav-button {
      display: none;
      text-align: right;

      .glyphicons {
        font-size: 20px;
        color: $logoBlue;
        line-height: 80px;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: $mediaQueryXXLarge) {
  .ccm-page {
    header {
      .container-fluid {
        max-width: 86%;
      }
    }
  }
}

@media only screen and (max-width: $mediaQueryXLarge) {
  .ccm-page {
    header {
      .container-fluid {
        max-width: 92%;
      }

      .desktop-nav {
        ul {
          li {
            a {
              padding: 0 30px;
            }

            &:first-of-type {
              a {
                padding-left: 6px;
              }
            }

            &:last-of-type {
              a {
                padding-right: 6px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaQueryLarge) {
  .ccm-page {
    header {
      .container-fluid {
        max-width: 92%;
      }

      .desktop-nav {
        display: none;
      }

      .nav-button {
        display: block;
      }

      .mobile-nav {
        nav {
          ul {
            li {
              a {
                &.nav-selected {
                  &:after {
                    margin: 3px auto 0 auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaQueryXSmall) {
  .ccm-page {
    header {
      .logo {
        height: 50px;
      }

      .nav-button .glyphicons {
        line-height: 50px;
      }
    }
  }
}