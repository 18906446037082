.ccm-page {
  footer {
    padding: 20px 0 10px 0;
    background-color: #ffffff;
    font-size: 13px;

    .contact {
      a {
        padding-right: 20px;
        white-space: nowrap;

        span {
          padding-right: 3px;
        }
      }
    }

    .copyright {
      text-align: right;

      img {
        margin-bottom: -4px;
        margin-left: 5px;
      }
    }
  }
}

@media only screen and (max-width: $mediaQuerySmall) {
  .ccm-page {
    footer {
      text-align: center;
      background: $backgroundColorNav;

      .contact {
        margin-bottom: 20px;
      }

      .copyright {
        text-align: center;
      }
    }
  }
}