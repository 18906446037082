/* Style for sub_page *************************************************************************************************/

.ccm-page.page-template-sub-page {
  main {
    .top-background {
      &.home {
        min-height: auto;
      }

      .container-fluid.container-wide {
        position: relative;
        z-index: 20;

        .row {
          margin-bottom: 0;
        }
      }

      .spacer {
        position: absolute;
        bottom: -5px;
        height: calc(10vw + 5px);
        width: 100%;
        background: #fff;
        display: block;
      }
    }

    //.row {
    //  .col-sm-5 > p,
    //  .col-sm-6 > p,
    //  .col-sm-7 > p {
    //    margin-top: 0;
    //  }
    //}
  }
}